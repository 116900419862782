
import importer from './importer';
import L from 'leaflet';

const iconDefault = importer.img.require('markers/marker_default.png');

const icon1 = importer.img.require('markers/marker_lightblue.png');
const icon2 = importer.img.require('markers/marker_green.png');
const icon3 = importer.img.require('markers/marker_yellow.png');
const icon4 = importer.img.require('markers/marker_orange.png');
const icon5 = importer.img.require('markers/marker_violet.png');

class Markers{
    constructor(){
        this.iconDefault = new L.Icon ({
            iconUrl: iconDefault,
            iconSize: [35,45],
            popupAnchor:[0,10],
        })

        const markerIcon1 = new L.Icon ({
            iconUrl: icon1,
            iconSize: [35,45],
            popupAnchor:[0,10],
        })
        const markerIcon2 = new L.Icon ({
            iconUrl: icon2,
            iconSize: [35,45],
            popupAnchor:[0,10],
        })
        const markerIcon3 = new L.Icon ({
            iconUrl: icon3,
            iconSize: [35,45],
            popupAnchor:[0,10],
        })
        const markerIcon4 = new L.Icon ({
            iconUrl: icon4,
            iconSize: [35,45],
            popupAnchor:[0,10],
        })
        const markerIcon5 = new L.Icon ({
            iconUrl: icon5,
            iconSize: [35,45],
            popupAnchor:[0,10],
        })
    

        this.levels = [markerIcon1, markerIcon2, markerIcon3, markerIcon4, markerIcon5];

    }

    get(level){
        if(level){
            return this.levels[level];
        }
        return this.iconDefault;
    }

}

export default new Markers();