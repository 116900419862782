import english from "../helpers/languages/english";
import italian from "../helpers/languages/italian";

import { enUS, itIT } from '@mui/x-data-grid';


import LocalizedStrings from 'react-localization';

const languages = (language) => {
    
    console.log(language);

    let strings = new LocalizedStrings({
        english,
        italian
    });

    strings.setLanguage(language);
    
    strings.datagrid = (language === 'italian') ? itIT : enUS;

    return strings;
}

 export default languages;