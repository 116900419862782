import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';



export default function CustomModal(props){
  
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      >

      <Box sx={style}>
        {props.content}
      </Box>
      
    </Modal>
  )
};


const style = {
  width:'50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px',
  borderRadius:'10px',
  boxShadow: 24,
  p: 5,
};