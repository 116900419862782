const importer = {
    img:{
        require: (filename, extension) => {
            if(extension){
                if( extension.includes('.') ){
                    return require('../../assets/img/' + filename + extension);
                }else{
                    return require('../../assets/img/' + filename + '.' + extension);
                }
            }else{
                return require('../../assets/img/' + filename);
            }
        }
    }
}

export default importer;