import * as React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from '@mui/material/useMediaQuery';

// mui-icon 
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import SensorsIcon from '@mui/icons-material/Sensors';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import SummarizeIcon from '@mui/icons-material/Summarize';
import importer from '../helpers/importer';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
// import theme from '../helpers/theme';
import { makeStyles } from '@mui/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import AccountPopover from '../components/AccountPopover';

import Map from './Map';
import Users from './Users';
import NodesList from './NodesList';
import NodeChart from './NodeChart';
import Profile from './Profile';
import Logs from './Logs';
import PropTypes from 'prop-types';

import jwtDecode from "jwt-decode";


const drawerWidth = 240;
const useStyles = makeStyles({
    logo_toolbar:{
        width: '100% !important',
        display: 'flex',
        alignItems: 'center',
        padding: '0px !important',
        marginTop: '50px',
        marginBottom: '50px',
        },
    logo_img:{
        width: '75%',
        margin: 'auto'
        },
    ListItem:{
        paddingLeft: '20px !important',
        paddingRight: '25px !important',
        margin: 'auto !important',
        marginTop: '20px !important',
        marginBottom: '20px !important',
        
        '&:hover' : {
            backgroundColor: 'unset !important',
            color: 'rgb(206, 59, 51)'
        },
        '&:hover .ListItemIcon' : {
            color: 'rgb(206, 59, 51)'
        },
        '& .ListItemText > span' : {
            fontSize: '16px !important',
        }
    },
    logoutButton:{
        paddingLeft: ' 0px !important',
        paddingRight: ' 0px !important',
        margin: 'auto !important',
        marginTop: '10px !important',
        marginBottom: '30px !important',
        width: '75% !important',
        '&:hover' : {
            backgroundColor: 'unset !important',
            color: 'rgb(206, 59, 51)'
        },
        '&:hover .ListItemIcon' : {
            color: 'rgb(206, 59, 51)'
        },
        '& .ListItemText > span' : {
            fontSize: '16px !important',
        },
    },
    copyright: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        backgroundColor: 'white',
        textAlign: 'center',
        marginBottom: '0px',
        lineHeight: '20px',
        fontSize: '12px'
    }
});

function SiteContent(props){
    console.log('sitecontent')
    const [open, setOpen] = useState(true);
   
    

    //const isDesktop = useResponsive('up', 'lg');
    const isDesktop = useMediaQuery('(min-width: 660px)');
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        // setMobileOpen(false);
      };


    const classes = useStyles();
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const {role} = decoded;
    // console.log(role);

    const { sitecontent_window } = props;
    
    
    const handleLogout = () => {
        props.logout();
    }
 
    
    const handleDrawerOpen = () => {
        let value = !open;
        setOpen(value);
        setMobileOpen(value) ;
    };

    const handleClickAway = () => {
        console.log("click");
        setOpen(false);
    };
    

    let mainNavSuperAdmin = [

       
        {text: 'Mappa', icon: (<MapIcon/>), path: 'map'},
        {text: 'Nodi', icon: (<SensorsIcon/>), path: 'nodes'},
        {text: 'Utenti', icon: (<AccessibilityIcon/>), path: 'users'},     
        {text: 'Log', icon: (<SummarizeIcon/>), path: 'logs'},

    ];
    let mainNavOperatore= [
        {text: 'Mappa', icon: (<MapIcon/>), path: 'map'},
        {text: 'Nodi', icon: (<SensorsIcon/>), path: 'nodes'},
       
    ]

    const copyrightText = {
        all: 'unset',
        cursor: 'pointer',
      
    }

    const drawer = (
        <>
        {isDesktop ?
            
                <Box sx={{
                    position: 'relative',
                    height: '100%', 
                    display: 'flex',
                    flexDirection: 'column',
                    borderRight: '1px solid rgba(206, 59, 51, 0.25)'
                    }}
                >
             
              
                    <Toolbar className={classes.logo_toolbar}>
                       
                        <img alt='...' className={classes.logo_img} src={importer.img.require('logotipo_orizzontale.png')} onClick={() => { handleDrawerOpen() } } /> 

                       
                    </Toolbar>

                    
    
                    
            
                

                    <Box sx={{overflowY: 'scroll'}}>
                        <List sx={{padding: 0}}>
                        {
                            (role == 1 || role == 2) ? 

                                mainNavSuperAdmin.map((item, index) => (
                                    <ListItem button key={item.text} className={classes.ListItem} onClick={ () => { navigate('/'+item.path, {replace: true}) }} disableRipple={true}>
                                        <ListItemIcon className={'ListItemIcon'} >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} className={'ListItemText'}/>
                                    </ListItem>
                                )) 
                                
                                :

                                mainNavOperatore.map((item, index) => (
                                    <ListItem button key={item.text} className={classes.ListItem} onClick={ () => { navigate('/'+item.path, {replace: true}) }} disableRipple={true}>
                                        <ListItemIcon className={'ListItemIcon'} >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} className={'ListItemText'} />
                                    </ListItem>
                                )) 

                                
                        }

                        </List>
                    </Box>
                    <Divider variant="middle" />
                    <List>
                        <ListItem button key={'Logout'} className={classes.logoutButton} onClick={() => { handleLogout();}} disableRipple={true}>
                            <ListItemIcon className={'ListItemIcon'}>
                                <MeetingRoomIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'Logout'} className={'ListItemText'}/>
                        </ListItem>
                    </List>
                    <Box className={classes.copyright}> 
                        <a style={copyrightText} href='https://www.pmf-research.eu/' >&copy; PMF RESEARCH</a>
                    </Box>
                </Box>
            

            :

           
                <Box sx={{
                    position: 'relative',
                    height: '100%', 
                    display: 'flex',
                    flexDirection: 'column',
                    borderRight: '1px solid rgba(206, 59, 51, 0.25)'
                    }}
                >
                    {!isDesktop ? 
                        <Button style={{
                            width: "5px",
                            marginLeft: "auto",
                            marginTop: "20px",
                        }}
                        onClick={() => { handleDrawerOpen() }}
                        >
                            < CloseSharpIcon style={{color:"rgba(0, 0, 0, 0.54)"}} />
                        </Button> 
                        : 
                        
                        <></>
                    }
                
                
                    <Toolbar className={classes.logo_toolbar}>
                        <img alt='...' className={classes.logo_img} src={importer.img.require('logotipo_orizzontale.png')} onClick={() => { handleDrawerOpen() } } /> 
                    </Toolbar>
                    

                    <Box sx={{overflowY: 'scroll'}}>
                        <List sx={{padding: 0}}>
                        {
                            (role == 1 || role == 2) ? 

                                mainNavSuperAdmin.map((item, index) => (
                                    <ListItem button key={item.text} className={classes.ListItem} onClick={ () => { navigate('/'+item.path, {replace: true}) }} disableRipple={true}>
                                        <ListItemIcon className={'ListItemIcon'} >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} className={'ListItemText'}/>
                                    </ListItem>
                                )) 
                                
                                :

                                mainNavOperatore.map((item, index) => (
                                    <ListItem button key={item.text} className={classes.ListItem} onClick={ () => { navigate('/'+item.path, {replace: true}) }} disableRipple={true}>
                                        <ListItemIcon className={'ListItemIcon'} >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} className={'ListItemText'} />
                                    </ListItem>
                                )) 

                                
                            }

                        </List>
                    </Box>
                    <Divider variant="middle" />
                    <List>
                        <ListItem button key={'Logout'} className={classes.logoutButton} onClick={() => { handleLogout();}} disableRipple={true}>
                            <ListItemIcon className={'ListItemIcon'}>
                                <MeetingRoomIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'Logout'} className={'ListItemText'}/>
                        </ListItem>
                    </List>
                    <Box className={classes.copyright}> 
                        <a style={copyrightText} href='https://www.pmf-research.eu/' >&copy; PMF RESEARCH</a>
                    </Box>
                </Box>
                    
            
        
        }

        </>
    );
    const container = sitecontent_window !== undefined ? () => sitecontent_window().document.body : undefined;

    


    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', position: 'relative', height: '100%'}}>


                    <Box sx={{display: 'flex', flexDirection: 'row', position: 'relative', height: '100%'}}>
                        
                        
                        {!isDesktop && (
                            <Drawer
                            container={container}
                            variant="temporary"
                            onClose={() => { handleDrawerToggle() } }
                            ModalProps={{
                                keepMounted: true, 
                            }}
                            sx={{
                                transition: 'width 225ms',
                                width: { sm: open ? drawerWidth : 0 },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none'},
                            }}
                            open={mobileOpen}
                            >
                            {drawer}
                            </Drawer>
                        )}  

                        {isDesktop && (
                            <>
                                <Drawer
                                    variant="persistent"
                                    sx={{
                                        transition: 'width 225ms',
                                        width: { sm: open ? drawerWidth : 0 },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none'},
                                    }}
                                    open={open}
                                    >
                                    {drawer}
                                </Drawer>


                                
                            </>
                        )}

                            
                    <Box
                        sx={
                            {
                                height: 'calc( 100% - 64px )',
                                width: '100%',
                                boxSizing: 'border-box',
                            }
                        }
                    >
                    <Toolbar sx={
                                {              
                                backgroundColor: 'white'
                                }
                            }>
                        <IconButton onClick={() => { handleDrawerOpen() } }>
                            <MenuIcon/>
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }} />
                        <AccountPopover logout={ props.logout }/>
                    </Toolbar>
                        <Box
                            position="relative"
                            component="main"
                            sx={
                                {              
                                    p: isDesktop ? 3: 0,
                                    paddingBottom: 0,
                                    //paddingTop: isDesktop? 3: 2,                        
                                    paddingTop: 0,                        
                                    backgroundColor: '#F7F8F8',
                                
                                    height: 'calc(100%)',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    boxShadow: 'inset 0 0 10px #00000010',

                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                }
                            }
                        >
                                <Container
                                    maxWidth="lg"
                                    sx={
                                        {
                                            height: '100%',
                                            position: 'relative',
                                        }
                                    }
                                >
                                <Routes>
                                    <Route path="/" element={<Map />} />
                                    <Route path="/map" element={<Map/>} />
                                    <Route path="/users" element={<Users/>} />
                                    <Route path="/nodes" element={<NodesList/>} /> 
                                    <Route path="/nodechart" element={<NodeChart/>} />
                                    <Route path="/profile" element={<Profile/>}/>
                                    <Route path="/logs" element={<Logs/>}/>
                                    <Route
                                        path="*"
                                        element={<Navigate to="/" />}
                                    />
                                </Routes>
                            <Box style={{position:"relative", paddingTop:"40px"}}>
                {isDesktop ?
                    
                         <section style={{ backgroundColor:'#404040',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center", }}>
                            
                            <img style={{width:'80%', paddingTop:"20px"}} alt="register" src ={ importer.img.require('gruppo_loghi_bianco.png') }/> 

                            <h3 style={{color:"#808080", fontSize:"12px",  textAlign:"justify", paddingLeft:"20px", paddingRight:"20px"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, ee la Commisisone non può essere ritenuta responsabile per l'uso che può essere ritenuta respoonsabile per l’uso che può essere fatto delle informazioni ivi contenute. </h3>
                            <h3 style={{color:"#808080", fontSize:"12px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3>
                                        
                        </section>  


                        :

                    <Box>

                        <section style={{padding:'10px 5px 10px 5px', backgroundColor:'#404040',display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center"}}>
                                
                            <img style={{width:'90%'}} alt="register" src ={ importer.img.require('gruppo_loghi_bianco.png') }/> 

                            <h3 style={{marginTop:'10px', color:"#808080", fontSize:"8px",  textAlign:"justify"}}>Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, ee la Commisisone non può essere ritenuta responsabile per l'uso che può essere ritenuta respoonsabile per l’uso che può essere fatto delle informazioni ivi contenute. </h3>
                            <h3 style={{marginTop:'10px', color:"#808080", fontSize:"6px", textAlign:"center"}}>N. Progetto 08CT6202000208 – CUP G69J18001010007</h3>
                            
                        </section>
                    </Box>
                   
                }





            </Box>
                                
                            </Container>


                       
                            
                        </Box>

                       
                    </Box>
                   
                

                </Box>                  
                 
                
                
                







                
            </Box>

         
               




            
            
                            
   
            
            

           

        </>
    );
}
SiteContent.propTypes = {

 sitecontent_window: PropTypes.func,
};
export default SiteContent;











