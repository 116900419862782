import { useState, useEffect } from 'react';
import Table from '../components/Table';
import Box from '@mui/material/Box';
import NodeChart from '../viewmodels/NodeChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import IconButton from '@mui/material/IconButton';
import config from '../config';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate} from 'react-router-dom';


const axios = require('axios');

function NodesList(props) {

    const navigate = useNavigate();
    const [nodes, setNodes] = useState([]);
    const token = localStorage.getItem('token');

    const getNodes = async () => {
        axios.get((config.apis.nodes), {headers: {
        'Authorization': `Bearer ${token}`
        }}).then((response)=>{
            setNodes(response.data);
        });
       
    };
   
    useEffect(()=>{
        getNodes();
    },[])

    console.log(nodes);
    return(
       <>
           <Box height="20px"> </Box>

            <Table 
                sx={{ paddingTop: '20px' }}
                data={nodes}
                columns={[
                        
                        {
                            flex: 1,
                            field: 'name',
                            headerName: 'Node',
                            renderCell: ({value}) => (value)
                        },
                        {
                            flex: 1,
                            field: 'ip',
                            headerName: 'IP',
                            renderCell: ({value}) => (value)
                        },
                        {
                        flex: 1,
                        field: 'lat',
                        headerName: 'Latitude',
                        renderCell: ({value}) => (value)
                        },
                        {
                        flex: 1,
                        field: 'long',
                        headerName: 'Longitude',
                        renderCell: ({value}) => (value)
                        },

                        {
                            flex: 1,
                            field: 'apikey',
                            headerName: 'Dettagli',
                            renderCell: ({value, row}) => {
                              return (
                                <>
                            
                                    <IconButton 
                                    onClick={() => {navigate('/nodechart', {state:{id: (value), name:row.name}})} }
                                        >
                                        <BarChartIcon style = {{color:"#ce3b33"}} />
                                    </IconButton>
                        
                                </>
                                    

                                    
                              )
                            }
                        }
                     
                        
                ]}
                
            />
   </> 
        
    )
}

export default NodesList;