import React from "react";



import useMediaQuery from '@mui/material/useMediaQuery';


const PasswordStrenghtIndicator = ({validity: {minChar, number, specialChar, chars}}) => {
    const isDesktop = useMediaQuery('(min-width: 660px)');
    return(
        <div style={{height: !isDesktop? "50%": "100%"}}>
            <p style={{fontSize: "15px", color:"gray", margin: "0 0 0 0"}}>La password deve contenere almeno:</p>
           
            <ul>
                <PasswordStrenghtIndicatorItem  isValid={chars} text="Un carattere maiuscolo"/>
                <PasswordStrenghtIndicatorItem  isValid={minChar} text="Una lunghezza minima di 8 caratteri"/>
                <PasswordStrenghtIndicatorItem  isValid={number} text="Contenere almeno un numero"/>
                <PasswordStrenghtIndicatorItem  isValid={specialChar} text="Avere almeno un carattere speciale"/>
            </ul>
            
        </div>
    );
};

const PasswordStrenghtIndicatorItem = ({isValid, text}) => {
    const isDesktop = useMediaQuery('(min-width: 660px)');
    const highlightClass = {fontSize: "15px" ,color: isValid ? "green": "red"}
   
    return <li style={highlightClass}>{text}</li>
}

export default PasswordStrenghtIndicator;