import { BrowserRouter as Route, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
//import materialUI 
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { Card, Link, Container, Typography } from '@mui/material';
import { maxHeight, maxWidth, minHeight, minWidth } from "@mui/system";
import { Stack, TextField, Select, ChangeEvent, FormControl, MenuItem, IconButton,  InputLabel, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//import helper-> per laggiunta delle immagini
import importer from '../helpers/importer';
import theme from '../helpers/theme';
import config from '../config';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from '@mui/material/useMediaQuery';
import { CenterFocusStrong } from "@mui/icons-material";

import PasswordStrenghtIndicator from "../components/PasswordStrenghtIndicator";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import jwtDecode from "jwt-decode";

const axios = require('axios').default;

const isNumberRegx = /\d/;
const regExpWeak = /[a-z]/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const chars = /[A-Z]{3}([0-9]{1,4})?|[A-Z]{1,3}/;
let allAreas;
export default function FormProfile(props){
    console.log("props",props.value)
      
    const [areas, setAreas] = useState('');
    const token = localStorage.getItem('token');
    const [user, setUser] = useState();
    const [flag, setFlag] = useState();
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("aaa");
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null, 
        bChar: null,
    });
    
    const onChangePassword = pw =>{
        setPassword(pw);
        setPasswordValidity({ 
            minChar: pw.length >= 8 ? true : false,
            number: isNumberRegx.test(pw) ? true : false,
            specialChar: specialCharacterRegx.test(pw)? true : false, 
            chars: chars.test(pw)? true : false,
        });
        
      

    }

    const [filteredAreas, setFilteredAreas] = useState([]);
    const [selectedArea_1, setSelectedArea_1] = useState("");
    const [filteredAreas_2, setFilteredAreas_2] = useState([]);
    const [selectedArea_2, setSelectedArea_2] = useState("");
    const [filteredAreas_3, setFilteredAreas_3] = useState([]);
    const [selectedArea_3, setSelectedArea_3] = useState("");
    const [filteredAreas_4, setFilteredAreas_4] = useState([]);
    const [selectedArea_4, setSelectedArea_4] = useState("");
    const [areas_2, setAreas_2] = useState('');
    const [areas_3, setAreas_3] = useState('');
    const [areas_4, setAreas_4] = useState('');
    const [areas_5, setAreas_5] = useState('');

    const getAreas = async () => {
        try {
         
            const response = await axios.get(config.apis.areas);
            allAreas = response.data;
            const filteredAreas = allAreas[0].areas.areasChildren.filter(
                (item) => item.areaParent.type === 1 ||  item.areaParent.type === 2
            );
            setFilteredAreas(filteredAreas); 
           
            const default_filteredAreas_2 = filteredAreas.filter((item) => item.areaParent.id === props.value.interestArea)[0].areasChildren;
            setFilteredAreas_2(default_filteredAreas_2);

            
            const default_filteredAreas_3 = filteredAreas.filter((item) => item.areaParent.id === props.value.interestArea)[0].areasChildren.filter((item) => item.areaParent.id === props.value.interestArea2)[0].areasChildren;
            setFilteredAreas_3(default_filteredAreas_3);

            const default_filteredAreas_4 = filteredAreas.filter((item) => item.areaParent.id === props.value.interestArea)[0].areasChildren.filter((item) => item.areaParent.id === props.value.interestArea2)[0].areasChildren.filter((item) => item.areaParent.id === props.value.interestArea3)[0].areasChildren;
            setFilteredAreas_4(default_filteredAreas_4);
            
            

        } catch (error) {
          console.error("Errore durante il recupero delle aree:", error);
        }
    };
    useEffect(() => {
        setAreas_2(props.value.interestArea2);
        setAreas_3(props.value.interestArea3);
        setAreas_4(props.value.interestArea4);
        getAreas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAreaChange = (event) => {
        const selectedAreaId = event.target.value;
        setAreas(event.target.value);
        setSelectedArea_1(selectedAreaId);
    
        // Ottenere i children dell'area selezionata
        const selectedAreaData = filteredAreas.find(
          (area) => area.areaParent.id === selectedAreaId
        );
    
        if (selectedAreaData) {
          setFilteredAreas_2(selectedAreaData.areasChildren);
           setFilteredAreas_3([]);
           setFilteredAreas_4([]);
           setAreas_2(""); //props.value.interestArea2 = "";
           setAreas_3("");
           setAreas_4("");
          
        } else {
          setFilteredAreas_2([]);
        }
        console.log("filteredAreas_2",filteredAreas_2);
    };

    const handleAreaChange_2 = (event) => {
        const selectedAreaId_2 = event.target.value;
        setAreas_2(event.target.value);
        setSelectedArea_2(selectedAreaId_2);
    
      
        const selectedAreaData_2 = filteredAreas_2.find(
          (area) => area.areaParent.id === selectedAreaId_2
        );
    
        if (selectedAreaData_2) {
            setFilteredAreas_3(selectedAreaData_2.areasChildren);
            setFilteredAreas_4([]);
            setAreas_3("");
            setAreas_4("");
        } else {
          setFilteredAreas_3([]);
        }
    };

    const handleAreaChange_3 = (event) => {
        const selectedAreaId_3 = event.target.value;
        setAreas_3(event.target.value);
        setSelectedArea_3(selectedAreaId_3);
    
      
        const selectedAreaData_3 = filteredAreas_3.find(
          (area) => area.areaParent.id === selectedAreaId_3
        );
    
        if (selectedAreaData_3) {
            setFilteredAreas_4(selectedAreaData_3.areasChildren);
            setAreas_4("");
        } else {
            setFilteredAreas_4([]);
        }
    }

    const handleAreaChange_4 = (event) => {
        const selectedAreaId_4 = event.target.value;
        setAreas_4(event.target.value);
        setSelectedArea_4(selectedAreaId_4);
    
       
        const selectedAreaData_4 = filteredAreas_3.find(
          (area) => area.areaParent.id === selectedAreaId_4
        );
    
        // if (selectedAreaData_4) {
        //   setFilteredAreas_5(selectedAreaData_4.areasChildren);
        // } else {
        //   setFilteredAreas_5([]);
        // }
       
    }


    const handleChange = (event) => {
    setAreas(event.target.value);
    };
    
    const TextFieldStyle = {
        borderRadius: '10px',
        backgroundColor:"#fff",
    };
    //alert
    const alert = withReactContent(Swal);

    // update informazioni 
    console.log(token);
    const decoded = jwtDecode(token);
    const {id} = decoded;

    let updateProfile = (e) => {
        e.preventDefault();

        let formData = new FormData(e.target);
        var payload = {};
        formData.forEach(function(value, key){
            payload[key] = value;
        });
         
        console.log(payload)
       
        axios.put(config.apis.users.updateProfile + id + '/updateProfile', {params: {payload}},  {headers: { 
            Authorization : `Bearer ${token}`
        }}).then(
            (response) => {
                console.log(response);
            }
        ).catch((error) => {
            switch(error.response.status){
                case 406 : {
                    alert.fire({
                        icon: 'warning',    
                        text:'Alcuni campi sono vuoti!',
                        confirmButtonColor: '#CE3B33',
                    })
                    break;
                };

                default:{
                    alert.fire({
                        icon: 'warning',    
                        text: 'Valore dei campi non validi!',
                        confirmButtonColor: '#CE3B33',   
                    })
                break;
            }

            }
        })
    }  
    
    let updatePassword = (e) => {
        e.preventDefault();

        let formData = new FormData(e.target);
        var payload = {};
        formData.forEach(function(value, key){
            payload[key] = value;
        });

        if((password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars)) && password.length <= 8){
            e.preventDefault();
            
            
            // alert formato password non valido
           alert.fire({
                icon: 'error', 
                text: 'Il formato della password non è valido!',
                confirmButtonColor: '#CE3B33',
            }); 
           
        
            
        }else{

            axios.patch(config.apis.users.updatePassword + id +'/updatePassword', {params: {payload}},  {headers: { 
                Authorization : `Bearer ${token}`
            }}).then(
                (response) => {
                    console.log(response);
                    alert.fire({
                        icon:'success',
                        text:'Password modificata con successo!',   
                        confirmButtonColor: '#CE3B33',
                    })
                }
            ).catch((error) => {
                console.log(error);
                switch(error.response.status){
                 
               
                    case 406 : {
                        alert.fire({
                            icon: 'error', 
                            text: 'Password attuale errata!',
                            confirmButtonColor: '#CE3B33',
                        })

                        break;
                    };
                    case 422 : {
                        alert.fire({ 
                            icon: 'error', 
                            text: 'Le due password non corrispondono',
                            confirmButtonColor: '#CE3B33'
                        })
                        break;
                    };
    
                    default:{
                        alert.fire({
                            icon: 'warning', 
                            text: 'Impossibile modificare la password!',
                            confirmButtonColor: '#CE3B33',
                        })
                    break;
                }
    
                }
            })
        }
      
        
    }  
  
  return (
   <>
        <form onSubmit={updateProfile} style={{marginTop:'100px'}}>
            <h1>Modifica i tuoi dati</h1>  
            <Stack spacing={1}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <TextField style = {TextFieldStyle}
                        fullWidth
                        defaultValue={props.value.name}
                        name = "name"
                        label = "Nome"
                        
                    />

                    <TextField style = {TextFieldStyle}
                        fullWidth

                        defaultValue={props.value.surname}
                        
                        name="surname"
                        label="Cognome"
                    />

                </Stack>

                    
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                        <TextField style = {TextFieldStyle}
                            fullWidth
                            
                            defaultValue={props.value.phone}
                            name="phone"
                            label="Numero di telefono"

                        />
                        <TextField style = {TextFieldStyle}
                            fullWidth
                            disabled
                            defaultValue={props.value.email}
                            label="email"

                        />
                    </Stack>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Area d'interesse</InputLabel>
                        <Select style = {TextFieldStyle}
                        name="interestArea"
                        labelId="interestArea-label"
                        id="interestArea"
                        defaultValue={props.value.interestArea}
                        label="Area d'interesse"
                        
                        onChange={handleAreaChange}
                        disabled
                        >
                        {filteredAreas.map((area) => (
                                <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                    {area.areaParent.name}
                                </MenuItem>
                        ))} 
                        </Select>
                    </FormControl>
                    {(selectedArea_1 || props.value.interestArea2) &&(
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sotto Area</InputLabel>
                            <Select style = {TextFieldStyle}
                            name="interestArea2"
                            labelId="interestArea-label"
                            id="interestArea2"
                            value={areas_2}
                            label="Sotto area"
                            
                            onChange={handleAreaChange_2}
                            disabled
                            >
                            {filteredAreas_2.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                            ))} 
                            </Select>
                        </FormControl>
                    )}

                    {(selectedArea_2 || props.value.interestArea3) &&(
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sotto Area</InputLabel>
                            <Select style = {TextFieldStyle}
                            name="interestArea3"
                            labelId="interestArea-label"
                            id="interestArea3"
                            value={areas_3}
                            label="Sotto area"
                            
                            onChange={handleAreaChange_3}
                            disabled
                            
                            >
                            {filteredAreas_3.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                            ))} 
                            </Select>
                        </FormControl>
                    )}

                    {(selectedArea_3 || props.value.interestArea4) &&(
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sotto Area</InputLabel>
                            <Select style = {TextFieldStyle}
                            name="interestArea4"
                            labelId="interestArea-label"
                            id="interestArea4"
                            value={areas_4}
                            label="Sotto area"
                            
                            onChange={handleAreaChange_4}
                            disabled
                            
                            >
                            {filteredAreas_4.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                            ))} 
                            </Select>
                        </FormControl>
                    )}


            </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained"  style={{backgroundColor: "#ce3b33" , color:"#fff", marginTop:"50px"}}>
            Salva
        </LoadingButton>

        
        </form>


        <form onSubmit={updatePassword} style={{marginTop:'100px'}}>
            
            <h1>Modifica la password</h1>
            <Stack spacing={1}>
                
                <TextField style = {TextFieldStyle}
                    fullWidth
                    name = "currentPassword"
                    label = "Password Attuale"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                            </IconButton>
                            </InputAdornment>
                        ),  
                    }}   
                />

                <TextField style = {TextFieldStyle}
                    fullWidth
                    name="newPassword"
                    label="Nuova Password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={ e => { onChangePassword(e.target.value) } }
                    onFocus={() => setPasswordFocused(true)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                </IconButton>
                            </InputAdornment>
                        ),  
                    }}
                />

                {passwordFocused && (<div style={{ height:"10px", display: password? 'flex' : 'block', justifyContent:'space-between',alignItems:'center', margin:'10px 0' }}>
                                 
                    <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                        backgroundColor: 
                            ((password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#fb0400" : "lightgray" }}/> 
                    <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                        backgroundColor: 
                            (password.length >= 3 && password.match(regExpWeak) && (password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#f5f491": "lightgray" }}/> 
                    <span style= {{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                        backgroundColor:
                            (password.length >= 3 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) || password.match(chars) )) ?  "#fbcb45": "lightgray" }}/> 
                    <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                        backgroundColor:
                            (password.length >= 8 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) && password.match(chars) )) ?  "#00eb1e": "lightgray" }}/>  
              
                    </div>
                )}


                { passwordFocused && (<PasswordStrenghtIndicator validity={passwordValidity}/>)}

                
                   
                <TextField style = {TextFieldStyle}
                    fullWidth
                    name="confirmPassword"
                    label="Conferma nuova password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                </IconButton>
                            </InputAdornment>
                        ),  
                    }}
                />
                
            </Stack>
        
            <LoadingButton fullWidth size="large" type="submit" variant="contained"  style={{backgroundColor: "#ce3b33" , color:"#fff", marginTop:"50px"}}>
                Modifica
            </LoadingButton>
        
                
        </form>
                
                
      
    </>
  )
};


