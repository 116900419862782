import { useState, useEffect } from 'react';
import { Stack, TextField, Select, ChangeEvent, FormControl, MenuItem, IconButton,  InputLabel, InputAdornment, Grid } from '@mui/material';
// import Modal from '../components/Modal';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import CustomModal from '../components/Modal';
import CustomModal2 from '../components/Modal_2';
import Table from '../components/Table';
import PasswordStrenghtIndicator from "../components/PasswordStrenghtIndicator";
import xhr from '../helpers/xhr';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import languages from "../languages";
import config from '../config';
import theme from '../helpers/theme';
import { autocompleteClasses } from '@mui/material';

import jwtDecode from "jwt-decode";
import { ThirtyFpsRounded } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';


const lang = languages('italian');
const axios = require('axios').default;

const isNumberRegx = /\d/;
const regExpWeak = /[a-z]/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const chars = /[A-Z]{3}([0-9]{1,4})?|[A-Z]{1,3}/;
let allAreas;

const useStyles = makeStyles({
    button:{
        backgroundColor: 'unset',
        color: theme.colors.secondary,
        
        '&:hover' : {
            backgroundColor: 'unset !important',
        },
        '&:active':{
            color: theme.colors.primary
        },
        '& *': {
            transition: 'none !important'
        }
    },
    buttonDanger:{
        backgroundColor: 'unset',
        color: theme.colors.dark,
        
        '&:hover' : {
            backgroundColor: 'unset !important',
        },
        '&:active':{
            color: theme.colors.primary,
        },
        '& *': {
            transition: 'none !important'
        }
    }
});

const buttonStyle = {
    backgroundColor : theme.colors.primary,
   
}
const TextFieldStyle = {
    borderRadius: '10px',
    backgroundColor:"#fff",
};



function Users(props){

    const [editOpen, setEditOpen] = useState(false);
    const [editOpen2, setEditOpen2] = useState(false);
    const editHandleOpen = () => setEditOpen(true);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const editHandleOpen2 = () => setEditOpen2(true);

    const [flagEdit, setFlagEdit] = useState ();
    
    const navigate = useNavigate();
    
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordValidity, setPasswordValidity] = useState({
        minChar: null,
        number: null,
        specialChar: null, 
        bChar: null,
    });
    
    // console.log(token);
    console.log(decoded);
    const {role} = decoded;
    const {_id}= decoded;
    console.log(role);

    const [filteredAreas, setFilteredAreas] = useState([]);
    const [selectedArea_1, setSelectedArea_1] = useState("");
    const [filteredAreas_2, setFilteredAreas_2] = useState([]);
    const [selectedArea_2, setSelectedArea_2] = useState("");
    const [filteredAreas_3, setFilteredAreas_3] = useState([]);
    const [selectedArea_3, setSelectedArea_3] = useState("");
    const [filteredAreas_4, setFilteredAreas_4] = useState([]);
    const [selectedArea_4, setSelectedArea_4] = useState("");
    const [areas, setAreas] = useState('');
    const [areas_2, setAreas_2] = useState('');
    const [areas_3, setAreas_3] = useState('');
    const [areas_4, setAreas_4] = useState('');
    const [areas_5, setAreas_5] = useState('');

    const getAreas = async () => {
        try {
         
            const response = await axios.get(config.apis.areas);
            allAreas = response.data;
            const filteredAreas = allAreas[0].areas.areasChildren.filter(
                (item) => item.areaParent.type === 1 ||  item.areaParent.type === 2
            );
            setFilteredAreas(filteredAreas); 
           
            // const default_filteredAreas_2 = filteredAreas.filter((item) => item.areaParent.id === user.interestArea)[0].areasChildren;
            // setFilteredAreas_2(default_filteredAreas_2);

            
            // const default_filteredAreas_3 = filteredAreas.filter((item) => item.areaParent.id === user.interestArea)[0].areasChildren.filter((item) => item.areaParent.id === user.interestArea2)[0].areasChildren;
            // setFilteredAreas_3(default_filteredAreas_3);

            // const default_filteredAreas_4 = filteredAreas.filter((item) => item.areaParent.id === user.interestArea)[0].areasChildren.filter((item) => item.areaParent.id === user.interestArea2)[0].areasChildren.filter((item) => item.areaParent.id === user.interestArea3)[0].areasChildren;
            // setFilteredAreas_4(default_filteredAreas_4);
            
            

        } catch (error) {
          console.error("Errore durante il recupero delle aree:", error);
        }
    };

    // useEffect(() => {
    //     console.log("user",user);
    //     setAreas_2(user.interestArea2);
    //     setAreas_3(user.interestArea3);
    //     setAreas_4(user.interestArea4);
    //     getAreas();
    // }, []);

  

    const handleAreaChange = (event) => {
        const selectedAreaId = event.target.value;
        setAreas(event.target.value);
        setSelectedArea_1(selectedAreaId);
    
        // Ottenere i children dell'area selezionata
        const selectedAreaData = filteredAreas.find(
          (area) => area.areaParent.id === selectedAreaId
        );
    
        if (selectedAreaData) {
            console.log("selectedAreaData");
            setFilteredAreas_2(selectedAreaData.areasChildren);
            setFilteredAreas_3([]);
            setFilteredAreas_4([]);
            setAreas_2(""); //user.interestArea2 = "";
            setAreas_3("");
            setAreas_4("");
          
        } else {
          setFilteredAreas_2([]);
        }
        console.log("filteredAreas_2",filteredAreas_2);
    };

    const handleAreaChange_2 = (event) => {
        const selectedAreaId_2 = event.target.value;
        setAreas_2(event.target.value);
        setSelectedArea_2(selectedAreaId_2);
    
      
        const selectedAreaData_2 = filteredAreas_2.find(
          (area) => area.areaParent.id === selectedAreaId_2
        );
    
        if (selectedAreaData_2) {
            console.log("selectedAreaData2");
            setFilteredAreas_3(selectedAreaData_2.areasChildren);
            setFilteredAreas_4([]);
            setAreas_3("");
            setAreas_4("");
        } else {
          setFilteredAreas_3([]);
        }
    };

    const handleAreaChange_3 = (event) => {
        const selectedAreaId_3 = event.target.value;
        setAreas_3(event.target.value);
        setSelectedArea_3(selectedAreaId_3);
    
      
        const selectedAreaData_3 = filteredAreas_3.find(
          (area) => area.areaParent.id === selectedAreaId_3
        );
    
        if (selectedAreaData_3) {
            setFilteredAreas_4(selectedAreaData_3.areasChildren);
            setAreas_4("");
        } else {
            setFilteredAreas_4([]);
        }
    }

    const handleAreaChange_4 = (event) => {
        const selectedAreaId_4 = event.target.value;
        setAreas_4(event.target.value);
        setSelectedArea_4(selectedAreaId_4);
    
       
        const selectedAreaData_4 = filteredAreas_3.find(
          (area) => area.areaParent.id === selectedAreaId_4
        );
    
        // if (selectedAreaData_4) {
        //   setFilteredAreas_5(selectedAreaData_4.areasChildren);
        // } else {
        //   setFilteredAreas_5([]);
        // }
       
    }





    const onChangePassword = pw =>{
        setPassword(pw);
        setPasswordValidity({ 
            minChar: pw.length >= 8 ? true : false,
            number: isNumberRegx.test(pw) ? true : false,
            specialChar: specialCharacterRegx.test(pw)? true : false, 
            chars: chars.test(pw)? true : false,
        });
    }
    
    // abilitazione utente
    async function updateUserActivation(idOperator, disabled){
        // console.log(_id, disabled)
        axios.patch(config.apis.users.enableUser + idOperator + '/enable', {params:{idOperator, disabled}}, {headers:{ 
            Authorization : `Bearer ${token}`
            }}).then((response)=>{
                let us = [...users];
                us.find(item => item._id == idOperator).disabled = response.data.disabled;
                setUsers(us);
            });
    }

    // modal 
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setPassword('')
    }
    const handleOpen2 = () => {
        setOpen2(true);
        
    }
    const handleClose2 = () => {
        setOpen2(false);
        setPassword('')
    }

    // stampa operatori   
    const getUsers = async () => {
        axios.get((config.apis.users.get),  {headers: {
        'Authorization': `Bearer ${token}`
        }}).then((response) => {
            setUsers(response.data);
        });
    };
    useEffect(()=>{
        getUsers();
    },[])
    
    useEffect(()=>{
        getUsers();
    },[flagEdit])


    // alert di errore se l'utente è già presente 
    const emailAlert = withReactContent(Swal);
    const editAlert = withReactContent(Swal);
    const pwAlert = withReactContent(Swal);

    // creazione operatore
    const handleSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData(e.target);
        const payload = {};
        
        formData.forEach((value, key) => {
          payload[key] = value;
        });
        console.log("payload", payload);
         // controllo validità password 
        if((password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars)) && password.length >= 8){
            
            axios.post((config.apis.users.new) , {params: {payload}}, 
          {headers :{
            'Authorization': `Bearer ${token}`
          }
        }).then(
          (response) => {
           // vengono restituuiti i dati dell'utente 
            console.log("utente creato", response.data);

            handleClose();

            Swal.fire({
                icon: "success",  
                text: 'Operatore creato con successo!',
                confirmButtonColor: '#CE3B33',   
            });

            let us = [...users];
            us.push(response.data);
            console.log(us);
            setUsers(us);
 
          }).catch(((error) => {
              switch(error.response.status){
                case 406: {
                    handleClose();
                    emailAlert.fire({
                        title: <p>Email già esistente!</p>,
                        icon: "error",        
                    })
                    break;
                }
                default:{
                
                break;
                }    
              }}
            ));    
        }else{      
            // alert formato password non valido
            pwAlert.fire({
                icon: 'error', 
                text: 'Il formato della password non è valido!',
                confirmButtonColor: '#CE3B33',
            });
        }
    }
    
        
      
    // modifica operatore
    const handleSubmit2 = (e) => {
        e.preventDefault();

      
        const formData = new FormData(e.target);
        const payload = {};
        
        formData.forEach((value, key) => {
          payload[key] = value;
        });
        console.log("payload", payload);
    
        const pw = payload['password'];

        if( pw && (password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars)) && password.length >= 8){
            

        }else if (password) {
            // alert formato password non valido
            handleClose2();

            pwAlert.fire({
                icon: 'error', 
                text: 'Il formato della password non è valido!',
                confirmButtonColor: '#CE3B33',
            });

            setUser(null);
            return;
        }
        

        axios.put((config.apis.users.edit + user._id +'/edit') , {params: {payload}}, 
        {headers :{
            'Authorization': `Bearer ${token}`
        }
        }).then((response) => {
                console.log("dati modificati",response.data);
                setFlagEdit (response.data);
                handleClose2();

                emailAlert.fire({
                    icon: "success",
                    title: 'Modifica con successo!',
                    confirmButtonColor: '#CE3B33',
                            
                })

                let us = [...users];

                us.find(item => item._id == user._id).name = response.data.name;
                us.find(item => item._id == user._id).surname = response.data.surname;
                console.log(us);
             
                
            }).catch((error) => {
                console.log(error);
                switch(error.response.status){
                    case 406: {
                        handleClose2();

                        emailAlert.fire({
                            title: 'Alcuni campi sono vuoti',
                            icon: "error",
                            confirmButtonColor: '#CE3B33',        
                        })
                        break;

                    }
                

                    default:{
                    
                    break;
                    }    
                }
            });
    }


    // Creazione operatore
    const modalContent = (
        <>
            <Box style={{height: "100%", width:"100%", display:"flex", flexDirection:"column", justifyContent: "center",position:"relative"}}>
             
                <form onSubmit={handleSubmit}  style={{ margin:"auto", width: '100%'}}>

                    <h1 style={{margin:"20px"}}> Crea Operatore </h1>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box style={{marginBottom: '10px'}}>

                                <TextField id="Nome" type="text" name="name" variant="outlined" label="Nome" fullWidth required/>

                            </Box>
                            
                            <Box style={{marginBottom: '10px'}}>
                                
                                <TextField id="Cognome" type="text" name="surname" variant="outlined" label="Cognome" fullWidth required />
                            
                            </Box>


                            <Box style={{marginBottom: '10px'}}>

                                <TextField id="Email" type="email" name="email" variant="outlined" label="Email" fullWidth required />
                            
                            </Box>
                            
                            <Box style={{marginBottom: '10px'}}>
                                <TextField id="phone" type="text" name="phone" variant="outlined" autoComplete="numero" label="Telefono" fullWidth />
                               
                            </Box>
                            

                            <Box style={{marginBottom: '10px'}}>

                            <TextField style = {TextFieldStyle}
                                    fullWidth
                                    name = "password"
                                    autoComplete="password attuale"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    
                                    onChange={ e => { onChangePassword(e.target.value) } }
                                    onFocus={() => setPasswordFocused(true)}
                                    required
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                        {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                            />
                        
                                <div style={{ height:"10px", display: password? 'flex' : 'block', justifyContent:'space-between',alignItems:'center', margin:'10px 0' }}>
                                        
                                        <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                        backgroundColor: 
                                            ((password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#fb0400" : "lightgray" }}/> 
                                        <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                        backgroundColor: 
                                            (password.length >= 3 && password.match(regExpWeak) && (password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#f5f491": "lightgray" }}/> 
                                        <span style= {{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                        backgroundColor:
                                            (password.length >= 3 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) || password.match(chars) )) ?  "#fbcb45": "lightgray" }}/> 
                                        <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                        backgroundColor:
                                            (password.length >= 8 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) && password.match(chars) )) ?  "#00eb1e": "lightgray" }}/>  
                                    
                                </div>

                                {passwordFocused && (<PasswordStrenghtIndicator validity={passwordValidity}/>)}
                        
                            </Box>

                        </Grid>
                        {/* <Grid item xs={6}>
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                                <InputLabel id="demo-simple-select-label">Area d'interesse</InputLabel>
                                <Select style = {TextFieldStyle}
                                    name="interestArea"
                                    labelId="interestArea-label"
                                    id="interestArea"
                                    value={areas}
                                    label="Area d'interesse"
                                    onChange={handleAreaChange}
                                    required
                                >
                                {filteredAreas.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                                ))}    

                                                      
                                </Select>
                            </FormControl>
                                
                            {selectedArea_1 && (
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                            <InputLabel id="demo-simple-select-label_2">Sotto area</InputLabel>
                                    <Select style = {TextFieldStyle}
                                    name="interestArea2"
                                    labelId="interestArea2-label"
                                    id="interestArea2"
                                    value={areas_2}
                                    label="Area d'interesse"
                                    onChange={handleAreaChange_2}
                                    
                                    >
                                    {filteredAreas_2.map((area) => (
                                        <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                            {area.areaParent.name}
                                        </MenuItem>
                                    ))}    
                                    </Select>
                            </FormControl>
                            )}
                                
                            {selectedArea_2 && (
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                                <InputLabel id="demo-simple-select-label_3">Sotto area</InputLabel>
                                <Select style = {TextFieldStyle}
                                    name="interestArea3"
                                    labelId="interestArea3-label"
                                    id="interestArea3"
                                    value={areas_3}
                                    label="Area d'interesse"
                                    onChange={handleAreaChange_3}
                                
                                >
                                    {filteredAreas_3.map((area) => (
                                        <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                            {area.areaParent.name}
                                        </MenuItem>
                                    ))}    
                                </Select>
                                
                            </FormControl>
                            )}

                            {selectedArea_3 && (
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                            <InputLabel id="demo-simple-select-label_4">Sotto area</InputLabel>
                                <Select style = {TextFieldStyle}
                                    name="interestArea4"
                                    labelId="interestArea4-label"
                                    id="interestArea4"
                                    value={areas_4}
                                    label="Area d'interesse"
                                    onChange={handleAreaChange_4}
                                    
                                >
                                    {filteredAreas_4.map((area) => (
                                        <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                            {area.areaParent.name}
                                        </MenuItem>
                                    ))}    
                                </Select>
                            </FormControl>    
                            )}
                        </Grid> */}
                    </Grid>
                        
                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                        <Button  style={{backgroundColor: '#ce3b33', margin:"20px 0 10px 0", width:"100%"}} variant="contained" type="submit">Crea Operatore</Button>

                    </Box>
                
                </form>
            
            </Box>
        </>

    );

    // modifica operatore 
    const modalContent2 = (
        
        (user ? 
            <Box style={{height: "100%", width:"100%", display:"flex", flexDirection:"column", justifyContent: "center",position:"relative"}}>
            
                <form onSubmit={handleSubmit2}  style={{margin:"auto", width:'100%'}}>
                    <h1 style={{margin:"20px"}}> Modifica operatore</h1>
                    
                    <Grid container spacing={2}>
                        
                        <Grid item xs={role==1? 6 : 12}>
                            <Box style={{marginBottom: '10px'}}>

                                <TextField id="Nome" type="text" name="name" variant="outlined" label="Nome" defaultValue={user.name} fullWidth required/>

                            </Box>
                            
                            <Box style={{marginBottom: '10px'}}>
                                
                                <TextField id="Cognome" type="text" name="surname" variant="outlined" defaultValue={user.surname} label="Cognome" fullWidth required />
                            
                            </Box>


                            <Box style={{marginBottom: '10px'}}>

                                <TextField  defaultValue={user.email} name="email" label="email" fullWidth  InputProps={{readOnly:true}} />
                            
                            </Box>

                            <Box style={{marginBottom: '10px'}}>

                            <TextField style = {TextFieldStyle}
                                    fullWidth
                                    name = "password"
                                    autoComplete="password attuale"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    
                                    onChange={ e => { onChangePassword(e.target.value) } }
                                    onFocus={() => setPasswordFocused(true)}
                                    
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                        {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                            />

        {passwordFocused && (<div style={{ height:"10px", display: password? 'flex' : 'block', justifyContent:'space-between',alignItems:'center', margin:'10px 0' }}>
                                        
                                    <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                    backgroundColor: 
                                        ((password.match(regExpWeak) || password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#fb0400" : "lightgray" }}/> 
                                    <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                    backgroundColor: 
                                        (password.length >= 3 && password.match(regExpWeak) && (password.match(isNumberRegx) || password.match(specialCharacterRegx) || password.match(chars))) ?  "#f5f491": "lightgray" }}/> 
                                    <span style= {{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                    backgroundColor:
                                        (password.length >= 3 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) || password.match(chars) )) ?  "#fbcb45": "lightgray" }}/> 
                                    <span style={{width:"100%", height:"100%", background:"lightgrey", borderRadius:"5px", margin:"0 3px", 
                                    backgroundColor:
                                        (password.length >= 8 && password.match(regExpWeak) && password.match(isNumberRegx) && (password.match(specialCharacterRegx) && password.match(chars) )) ?  "#00eb1e": "lightgray" }}/>  
                                
                                </div>
        )}
                                {passwordFocused && (<PasswordStrenghtIndicator validity={passwordValidity}/>)}
                            
                            </Box>


                            
                            
                        </Grid>
                        {role == 1 && (
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                                <InputLabel id="demo-simple-select-label">Area d'interesse</InputLabel>
                                <Select style = {TextFieldStyle}
                                    name="interestArea"
                                    labelId="interestArea-label"
                                    id="interestArea"
                                    value={areas}
                                    label="Area d'interesse"
                                    onChange={handleAreaChange}
                                    required
                                >
                                {filteredAreas.map((area) => (
                                    <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                        {area.areaParent.name}
                                    </MenuItem>
                                ))}    

                                {/* <MenuItem value={1}>Sicilia</MenuItem>
                                <MenuItem value={2}>Etna</MenuItem>
                                <MenuItem value={3}>Catania</MenuItem> */}
                        
                                </Select>
                            </FormControl>

                            {(selectedArea_1 || user.interestArea2) &&(
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                                <InputLabel id="demo-simple-select-label">Sotto Area</InputLabel>
                                <Select style = {TextFieldStyle}
                                name="interestArea2"
                                labelId="interestArea-label"
                                id="interestArea2"
                                value={areas_2}
                                label="Sotto area"
                                
                                onChange={handleAreaChange_2}
                                
                                >
                                {filteredAreas_2.map((area) => (
                                        <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                            {area.areaParent.name}
                                        </MenuItem>
                                ))} 
                                </Select>
                            </FormControl>
                        )}

                        {(selectedArea_2 || user.interestArea3) &&(
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                                <InputLabel id="demo-simple-select-label">Sotto Area</InputLabel>
                                <Select style = {TextFieldStyle}
                                name="interestArea3"
                                labelId="interestArea-label"
                                id="interestArea3"
                                value={areas_3}
                                label="Sotto area"
                                
                                onChange={handleAreaChange_3}
                                
                                >
                                {filteredAreas_3.map((area) => (
                                        <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                            {area.areaParent.name}
                                        </MenuItem>
                                ))} 
                                </Select>
                            </FormControl>
                        )}

                        {(selectedArea_3 || user.interestArea4) &&(
                            <FormControl fullWidth style={{marginBottom: '10px'}}>
                                <InputLabel id="demo-simple-select-label">Sotto Area</InputLabel>
                                <Select style = {TextFieldStyle}
                                name="interestArea4"
                                labelId="interestArea-label"
                                id="interestArea4"
                                value={areas_4}
                                label="Sotto area"
                                
                                onChange={handleAreaChange_4}
                                
                                >
                                {filteredAreas_4.map((area) => (
                                        <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                            {area.areaParent.name}
                                        </MenuItem>
                                ))} 
                                </Select>
                            </FormControl>
                        )}


                        </Grid>
                        )}
                    </Grid>
                    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>

                                <Button  style={{backgroundColor: '#ce3b33', margin:"20px 0 10px 0", width:"100%"}} variant="contained" type="submit" >Modifica</Button>

                    </Box>
                </form>
        
            </Box>
        :
            <></>
        )   
            
          
     
    );

    const editUser = async( userData ) => {
        setUser(userData); 
        //getAreas();  
        console.log("user", userData);

        setAreas(userData.interestArea);
        setAreas_2(userData.interestArea2);
        setAreas_3(userData.interestArea3);
        setAreas_4(userData.interestArea4);

        const response = await axios.get(config.apis.areas);
        allAreas = response.data;
        const filteredAreas = allAreas[0].areas.areasChildren.filter(
            (item) => item.areaParent.type === 1 ||  item.areaParent.type === 2
        );
        setFilteredAreas(filteredAreas); 
       
        const default_filteredAreas_2 = filteredAreas.filter((item) => item.areaParent.id === userData.interestArea)[0].areasChildren;
        console.log("default_filteredAreas_2", default_filteredAreas_2);
        setFilteredAreas_2(default_filteredAreas_2);
        
        if(default_filteredAreas_2 && userData.interestArea2){
            const default_filteredAreas_3 = default_filteredAreas_2.filter((item) => item.areaParent.id === userData.interestArea2)[0].areasChildren;
            console.log("default_filteredAreas_3", default_filteredAreas_3);
            setFilteredAreas_3(default_filteredAreas_3);

            if(default_filteredAreas_3 && userData.interestArea3){
                const default_filteredAreas_4 = default_filteredAreas_3.filter((item) => item.areaParent.id === userData.interestArea3)[0].areasChildren;
                console.log("default_filteredAreas_4", default_filteredAreas_4);
                setFilteredAreas_4(default_filteredAreas_4);
            }
        }

        
        
        

        // if(userData.interestArea2 != null){
        //     const default_filteredAreas_3 = filteredAreas.filter((item) => item.areaParent.id === userData.interestArea)[0].areasChildren.filter((item) => item.areaParent.id === userData.interestArea2)[0].areasChildren;
        //     setFilteredAreas_3(default_filteredAreas_3);
        // }
        // if(userData.interestArea3 != null){
        //     const default_filteredAreas_4 = filteredAreas.filter((item) => item.areaParent.id === userData.interestArea)[0].areasChildren.filter((item) => item.areaParent.id === userData.interestArea2)[0].areasChildren.filter((item) => item.areaParent.id === userData.interestArea3)[0].areasChildren;
        //     setFilteredAreas_4(default_filteredAreas_4);
        // }
        // setAreas_2(userData.interestArea2);
        // setAreas_3(userData.interestArea3);
        // setAreas_4(userData.interestArea4); 
       
        //getAreas(2);
       
        handleOpen2();
             
    }


    // cancellazione operatore 
    const deleteUser = async (_id) => {

        console.log(_id)
        // alert di conferma eliminazione
        Swal.fire({
            title: 'Vuoi eliminare il seguente operatore?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#CE3B33',
            denyButtonColor: '#CE3B33',
            customClass: {
              actions: 'my-actions',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                console.log('aa');
                console.log(_id);
                
                const payload = {
                   _id
                }
              
                axios.delete(config.apis.users.delete + _id, {headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {payload}} ).then(
                    (response) => {
                        console.log(response.data);
                        console.log('utente eliminato:',response._id);
                        Swal.fire({
                            icon: 'success',
                            text: 'Operatore cancellato!',
                            confirmButtonColor: '#CE3B33',
                        });
                        let us = users.filter( (user)=> user._id!=_id);
                        console.log(us);
                        setUsers(us);
                        
                    }
                )
                
        
                } else if (result.isDenied) {
                Swal.fire({
                    icon: 'success',
                    text: 'Operazione annullata',
                    confirmButtonColor: '#CE3B33'
                });
                }
        })      
    }
    



    return (
       
        <div style={{ width: "100%", height: "100%", marginTop: '2%'}}>
            {role == 2 && (
            <Button variant="contained" onClick={handleOpen} style={{backgroundColor:"rgba(206, 59, 51, 1)"}}>
                <AddIcon/>
                Crea Operatore
            </Button>
            )}
            

            <CustomModal open={open} handleClose={handleClose} content={modalContent}/> 

            <CustomModal2 open={open2} handleClose={handleClose2} content={modalContent2}/> 

            
            <Table
                
                style={{width:"10px"}}
                data={users}
                columns={[
                    {
                        type: 'actions',
                        headerName: 'Attivo',
                        field: 'active',
                        
                        sortable: false,
                        disableColumnMenu: true,
                        filterable: false,
                            
                        getActions: (params) => {
                            // console.log( params )
                            let handleSwitch = async () => {
                                let obj = null;
                                
                                updateUserActivation(params.row._id, params.row.disabled);

                            }

                            if (params.row.role > role){

                                return [
                                    <Switch checked={!params.row.disabled} onChange={(e) => { handleSwitch() }} inputProps={{ 'aria-label': 'controlled' }}/>

                                    // <AndroidSwitch onChange={(e) => { handleSwitch() }} checked={(params.row.active) ? true : false }/>
                                ]

                            }
                            
                            return [
                               <></>
                               
                            ]
                            
                        }
                    },
                    {
                        flex: 1,
                        field: 'name',
                        headerName: 'Nome',
                        renderCell: ({value}) => (value)
                    },
                    {
                        flex: 1,
                        field: 'surname',
                        headerName: 'Cognome',
                        renderCell: ({value}) => (value)
                    },
                    {
                        flex: 1,
                        field: 'email',
                        headerName: 'E-Mail',
                        renderCell: ({value}) => (value)
                    },
                    {
                        flex: 1,
                        field: 'role',
                        headerName: 'Ruolo',
                        renderCell: ({value}) => 
                            {   switch(value){
                                
                                    case 1 :{
                                        return "SuperAdmin";
                                    }
                                    case 2 :{
                                        return "Amministrativo";
                                    }
                                    case 3 :{
                                        return "Operatore";
                                    }

                                }
                            
                            }
                        
                        
                    },
    
                    {
                        type: 'actions',
                        field: '_id',
                        headerName: 'Azioni',
                        getActions: ( params ) => {

                            if (params.row.role > role){

                                return [
                                    <div>
                                        <Button size="small" onClick={()=> editUser(params.row)} className={classes.button} disableRipple={true} sx={{minWidth: 'unset'}}> <EditIcon /> </Button>
                                    
                                        <Button size="small" onClick={() => deleteUser(params.row._id)} className={classes.buttonDanger} disableRipple={true} sx={{minWidth: 'unset'}}> <DeleteIcon /> </Button>
                                    </div>
                                ]
                            }
                            return [
                               <> </>
                               
                            ]
                           
                        }
                    },
                ]}
            />
                {/* <Modal open={editOpen} handler={setEditOpen} title={'Edit user'} body={editBody}/> */}
        </div>
      );
}
export default Users;