import { useRef, useState, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate} from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from './MenuPopover';
// mocks_

import importer from '../helpers/importer';
import axios from 'axios';
import config from '../config';
import jwtDecode from "jwt-decode";


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Profilo',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
];

// ----------------------------------------------------------------------





export default function AccountPopover(props){
  const anchorRef = useRef(null);

  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const token = localStorage.getItem('token');
 
  console.log(token);
  const decoded = jwtDecode(token);


  const {name} = decoded;
  const {surname} = decoded;
  const {email} = decoded;

  
  console.log("utente:",decoded);



  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  
  


return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={importer.img.require('avatar_default.jpeg')} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {name} {surname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} onClick={() => {navigate('/profile', {replace: true})}}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={props.logout} sx={{ m:1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
