import { useState, useEffect } from 'react';
import { DataGrid,
    // GridToolbarContainer,
    // GridToolbarExport,
    // GridToolbarDensitySelector,
    // GridToolbarFilterButton,
    // GridToolbarColumnsButton,
}from '@mui/x-data-grid';

import languages from "../languages";

const lang = languages('italian');





export default function Table(props){
    //console.log(props);
   //const [data, setData] = useState([]); 
    
        // useEffect( () => {
        //     if(props.dataGetter){
        //         props.dataGetter().then(
        //             (response)=> {
        //                 setData(response.data);
        //             }
                    
        //         )
                
        //         console.log('Table', 'useEffect')
            
        //     }else if(props.data){
        //         // console.log(props.data)
        //         setData([...props.data]);
        //     }
            
        // }, []);

    return (
      
    <DataGrid
        getRowId={(row) => row._id}
        localeText={lang.datagrid.components.MuiDataGrid.defaultProps.localeText}
        
        sx={
            {
            border: 0
            }
        }
        
        rows = { props.data }

        pageSize={props.pageSize ? props.pageSize : 25}
        columns={props.columns}
/>
  )
};


