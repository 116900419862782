import { useState, useEffect } from 'react';
//  mui components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import jwtDecode from "jwt-decode";


// map
import MapComponent from '../components/MapComponent';

import theme from '../helpers/theme';
import config from '../config';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const axios = require('axios').default;


function Map(){
    const [checked, setChecked] = useState([0]);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
   
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };
    
    const [filteredAreas, setFilteredAreas] = useState([]);
    const [selectedArea_1, setSelectedArea_1] = useState("");
    const [filteredAreas_2, setFilteredAreas_2] = useState([]);
    const [selectedArea_2, setSelectedArea_2] = useState("");
    const [filteredAreas_3, setFilteredAreas_3] = useState([]);
    const [selectedArea_3, setSelectedArea_3] = useState("");
    const [filteredAreas_4, setFilteredAreas_4] = useState([]);
   

    const [areas, setAreas] = useState('');
    const [areas_2, setAreas_2] = useState('');
    const [areas_3, setAreas_3] = useState('');


    const handleAreaChange = (event) => {
        const selectedAreaId = event.target.value;
        setAreas(event.target.value);
        setSelectedArea_1(selectedAreaId);
        
        const selectedAreaData = filteredAreas.find(
            (area) => area.areaParent.id === selectedAreaId
          );
      
          if (selectedAreaData) {
            setFilteredAreas_2(selectedAreaData.areasChildren);
            setFilteredAreas_3([]);
            setFilteredAreas_4([]);
          } else {
            setFilteredAreas_2([]);
          }
          console.log("filteredAreas_2",filteredAreas_2);
      };
  
      const handleAreaChange_2 = (event) => {
          const selectedAreaId_2 = event.target.value;
          setAreas_2(event.target.value);
          setSelectedArea_2(selectedAreaId_2);
      
        
          const selectedAreaData_2 = filteredAreas_2.find(
            (area) => area.areaParent.id === selectedAreaId_2
          );
      
          if (selectedAreaData_2) {
            setFilteredAreas_3(selectedAreaData_2.areasChildren);
            setFilteredAreas_4([]);
          } else {
            setFilteredAreas_3([]);
          }
      };
  
      const handleAreaChange_3 = (event) => {
          const selectedAreaId_3 = event.target.value;
          setAreas_3(event.target.value);
          setSelectedArea_3(selectedAreaId_3);
      
        
          const selectedAreaData_3 = filteredAreas_3.find(
            (area) => area.areaParent.id === selectedAreaId_3
          );
      
          if (selectedAreaData_3) {
            setFilteredAreas_4(selectedAreaData_3.areasChildren);
          } else {
            setFilteredAreas_4([]);
          }
      }
        
    const TextFieldStyle = {
        borderRadius: '10px',
        backgroundColor:"#fff",
        marginRight: 10,
        marginBottom: 10
       
    };
    
    useEffect(() => {
        axios.get(config.apis.areas).then (response => {
            console.log("allAreas - response", response.data);
            const areas = response.data[0].areas.areasChildren.filter(
                (item) => item.areaParent.type === 1 ||  item.areaParent.type === 2
            );
            setFilteredAreas(areas); 
        });
    },[]);

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const isDesktop = useMediaQuery('(min-width: 660px)');

    console.log(token);
    console.log(decoded);
    const {role} = decoded;
    console.log(role);


    return(
       <>
        
        <Box sx={{backgroundColor:'#F7F8F8', marginTop: 2}}>
            <Grid container>
                {
                    (role == 1) ? 
                                                             
                        <Grid item xs={12}> 
                            <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}>
                               
                                <FormControl fullWidth sx={{marginRight:2}}>
                                    <InputLabel>Area d'interesse</InputLabel>
                                    <Select style = {TextFieldStyle}
                                    name="interestArea"
                                    labelId="interestArea-label"
                                    id="interestArea"
                                    value={areas}
                                    label="Sotto area"
                                    onChange={handleAreaChange}
                                    
                                    >
                                    {filteredAreas.map((area) => (
                                            <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                                {area.areaParent.name}
                                            </MenuItem>
                                    ))} 
                                    </Select>
                                </FormControl>
                               

                                {selectedArea_1 && (
                                <FormControl fullWidth>
                                    <InputLabel>Sotto Area</InputLabel>
                                    <Select style = {TextFieldStyle}
                                    name="interestArea"
                                    labelId="interestArea-label"
                                    id="interestArea"
                                    value={areas_2}
                                    label="Sotto area"
                                    onChange={handleAreaChange_2}
                                    
                                    >
                                    {filteredAreas_2.map((area) => (
                                            <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                                {area.areaParent.name}
                                            </MenuItem>
                                    ))} 
                                    </Select>
                                </FormControl>
                                )}

                                {selectedArea_2 && (
                                <FormControl fullWidth>
                                    <InputLabel>Sotto area</InputLabel>
                                    <Select style = {TextFieldStyle}
                                    name="interestArea"
                                    labelId="interestArea-label"
                                    id="interestArea"
                                    value={areas_3}
                                    label="Sotto area"
                                    onChange={handleAreaChange_3}
                                    
                                    >
                                    {filteredAreas_3.map((area) => (
                                            <MenuItem key={area.areaParent.id} value={area.areaParent.id}>
                                                {area.areaParent.name}
                                            </MenuItem>
                                    ))} 
                                    </Select>
                                </FormControl>
                                )}
                               
                            </Box>
                        </Grid>
                    
                    :

                    <></>

                }
      
              
                <Grid item xs={12} style={{position:"relative"}}>
                    <MapComponent filter={{role, areas, areas_2, areas_3}} style={{marginBottom:'30%'}} />
                </Grid>
        
            </Grid>
                
         

            

        </Box>
       </>
        
    );


}
export default Map;
    