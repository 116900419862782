import { useState, useEffect } from 'react';
import Table from '../components/Table';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

import config from '../config';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate} from 'react-router-dom';


const axios = require('axios');

function Logs(props) {

    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);
    const token = localStorage.getItem('token');

    const getLogs = async () => {
        axios.get((config.apis.eruption.listLogs), {headers: {
        'Authorization': `Bearer ${token}`
        }}).then((response)=>{
            setLogs(response.data);
        });
       
    };
   
    useEffect(()=>{
        getLogs();
    },[])

    const downloadLog =  async(filename) => {

        const response = await axios.get((config.apis.eruption.downloadLog) + filename, {
             headers: {
               Authorization: `Bearer ${token}`,
             },
           })
             
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Log_'+ filename;
        link.click();
        
    }

    console.log(logs);
    return(
       <>
           <Box height="20px"> </Box>

            <Table 
                sx={{ paddingTop: '20px' }}
                data={logs}
                columns={[
                        
                        {
                        flex: 1,
                        field: 'filename',
                        headerName: 'File',
                        renderCell: ({value}) => (value)
                        },
                        

                        {
                            flex: 1,
                            field: 'download',
                            headerName: 'Download',
                            renderCell: ({value, row}) => {
                               
                              return (
                                <>
                            
                                    <IconButton 
                                    onClick={() => {downloadLog(row.filename)} }
                                        >
                                        <DownloadIcon style = {{color:"#ce3b33"}} />
                                    </IconButton>
                        
                                </>
                                    

                                    
                              )
                            }
                        }
                     
                        
                ]}
                
            />
   </> 
        
    )
}

export default Logs;