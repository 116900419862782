import React from "react";


//import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from "./Login";
import Signin from "./Signin";
import ForgotPassword from "./ForgotPassord";
import SiteContent from "./SiteContent";

function Auth(props){
    return (
        <Routes>
            {/* user */}
            <Route path="/" element={<Login login={props.login}/>} />
            <Route path="/signin" element={<Signin/>} />
            <Route path="/forgot_password" element={<ForgotPassword/>} />

            
            <Route path="*" element={<Navigate to="/"/>}/>

        </Routes>
    )
    
}

export default Auth;