import { BrowserRouter as Route, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
//import materialUI 
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { Card, Link, Container, Typography } from '@mui/material';
import { maxHeight, maxWidth, minHeight, minWidth } from "@mui/system";
import { Stack, TextField, Select, ChangeEvent, FormControl, MenuItem, IconButton,  InputLabel, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//import helper-> per laggiunta delle immagini
import importer from '../helpers/importer';
import theme from '../helpers/theme';
import config from '../config';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from '@mui/material/useMediaQuery';
import { CenterFocusStrong } from "@mui/icons-material";
import FormProfile from "../components/FormProfile";

import jwtDecode from "jwt-decode";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { propsToClassKey } from "@mui/styles";

const axios = require('axios').default;

function Profile(){

    const token = localStorage.getItem('token');
    const [user, setUser] = useState();
    const [flag, setFlag] = useState();

    console.log(token);
    const decoded = jwtDecode(token);
    const {id} = decoded;
    console.log("id_user",id);
    
    //stampa delle informazioni dell'account
    
    useEffect(() => {
        console.log("profile")
         axios.get(config.apis.users.getProfile + id +'/getProfile', {headers: { 
            Authorization : `Bearer ${token}`
        }}).then((response)=> {
            console.log(response)
            setUser(response.data);
            
        })
    },[])

    return(
        <>
        {
            user ?
            <FormProfile value={user}/>
            :
            <></>
        }
        </>
    )
}

export default Profile;