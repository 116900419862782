import { useState, useEffect } from 'react'
// map 
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Polygon, Popup, useMapEvents, Circle, Pane } from 'react-leaflet';
// helper 
import markers from '../helpers/markers';
import config from '../config';
import jwtDecode from "jwt-decode";
import { Box } from '@mui/material';

const axios = require('axios');

const polygon = [
    [37.51186319048764, 15.095415627603161],
    [37.52112193704982, 15.007696669710635],
    [37.55486434257778, 15.145229328344168]
]
const purpleOptions = { color: 'lime' }
//const interestAreaForMap = [];




export default function MapComponent(props){
    console.log("config", config);
    const {filter} = props;
    console.log("filter", filter);
    const role = filter.role;
   
    const area = filter.areas;
    const area_2 = filter.areas_2;
    const area_3 = filter.areas_3;

    const [nodes, setNodes] = useState([]); 
    const [areas, setAreas] = useState([]);
    const [levelsAreas, setLevelsAreas] = useState(null);
    const token = localStorage.getItem('token');

    let [allAreas, setAllAreas] = useState([]);

   
    const triangleIcon = L.divIcon({
        className: 'triangle-icon',
        html: '<div></div>',
    });
    
   
    // const configAuthNodes = {
    //     method: 'get',
    //     url: config.apis.nodes,
    //     headers: { 
    //     'Authorization': `Bearer ${token}`
    //     }
    // };

    async function loadColor (){
        try{
        var configAxios = {
            method: 'get',
            url: (config.apis.areasColor),
            headers: { }
          };
          
         const response = await axios(configAxios);
         //DEVO mettere il volore che torna da questa API nel mio array
         console.log('response.data COLOR', response.data);
         setLevelsAreas (response.data);
         //this.levelsAreas = ['#FFD96E','#FEB640', '#FF8D32', '#FF5021', '#FF1218', '#DF0024', '#9A0024', '#5D0619', '#320009', '#060001'];

        } catch (error) { //gestisco il caso in cui la chiamata a swing non va a buon fine
            setLevelsAreas ([
                {
                "code": -1,
                "description": "Null",
                "color": "#FFFFFF"
                },
                {
                "code": 0,
                "description": "Lowest",
                "color": "#FFD96E"
                },
                {
                "code": 1,
                "description": "Very Low",
                "color": "#FEB640"
                },
                {
                "code": 2,
                "description": "Low",
                "color": "#FF8D32"
                },
                {
                "code": 3,
                "description": "Slightly Low",
                "color": "#FF5021"
                },
                {
                "code": 4,
                "description": "Mid",
                "color": "#FF1218"
                },
                {
                "code": 5,
                "description": "Mid High",
                "color": "#DF0024"
                },
                {
                "code": 6,
                "description": "Slightly High",
                "color": "#9A0024"
                },
                {
                "code": 7,
                "description": "High",
                "color": "#5D0619"
                },
                {
                "code": 8,
                "description": "Very High",
                "color": "#320009"
                },
                {
                "code": 9,
                "description": "Highest",
                "color": "#060001"
                }
                ]);
            console.error("Errore durante il recupero dei colori:", error);
        }
       
    }
    
        useEffect(() => {
            loadColor ();
            if (role != 1){
                axios.get((config.apis.nodes), {headers:{ 'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setNodes(response.data);
                    console.log(response)
                    console.log('Hook nodes');
                    console.log(nodes);
                }).catch((error) => {
                    console.log (error);
                });
                

                axios.get(config.apis.areas).then (response => {
                    console.log("allAreas - response", response.data);
                    setAllAreas(response.data[0]);
                    console.log(allAreas);
                    const decoded = jwtDecode(token);
                    console.log("TOKEN ",decoded);

                    const interestAreaForMap = [];
                        if(decoded.interestArea){
                            if(decoded.interestArea2){
                                if(decoded.interestArea3){
                                    if(decoded.interestArea4){
                                        console.log("interestArea4");
                                        const findArea = (((response.data[0].areas.areasChildren.find((item) => item.areaParent.id === decoded.interestArea)).areasChildren.find((item) => item.areaParent.id === decoded.interestArea2)).areasChildren.find((item) => item.areaParent.id === decoded.interestArea3)).areasChildren.find((item) => item.areaParent.id === decoded.interestArea4);
                                        visitTreeNodesAreas (findArea, interestAreaForMap);} 
                                    else{
                                        console.log("interestArea3");
                                        const findArea = ((response.data[0].areas.areasChildren.find((item) => item.areaParent.id === decoded.interestArea)).areasChildren.find((item) => item.areaParent.id === decoded.interestArea2)).areasChildren.find((item) => item.areaParent.id === decoded.interestArea3);
                                        visitTreeNodesAreas (findArea, interestAreaForMap);
                                    }
                                }else{
                                    console.log("interestArea2");
                                    const findArea = (response.data[0].areas.areasChildren.find((item) => item.areaParent.id === decoded.interestArea)).areasChildren.find((item) => item.areaParent.id === decoded.interestArea2);
                                    visitTreeNodesAreas (findArea, interestAreaForMap);
                                }
                            }else{
                                console.log("interestArea");
                                const findArea = response.data[0].areas.areasChildren.find((item) => item.areaParent.id === decoded.interestArea);
                                visitTreeNodesAreas (findArea, interestAreaForMap);
                                console.log("FIND: ",findArea);
                            }
                        }else{
                            const findArea = response.data[0].areas;
                                visitTreeNodesAreas (findArea, interestAreaForMap);
                                console.log("FIND: ",findArea);
                        }   
                        console.log("interestAreaForMap", interestAreaForMap);
                        setAreas(interestAreaForMap);
                

                });
            }
        },[]);
   
   

        useEffect(() => {
            if(role == 1){
                console.log("****************SuperAdmin*******************");
                axios.get((config.apis.nodes), {headers:{ 'Authorization': `Bearer ${token}`}})
                .then((response) => {
                    setNodes(response.data);
                    console.log(response)
                    // console.log('Hook nodes');
                    // console.log(nodes);
                }).catch((error) => {
                    console.log (error);
                });
                

                axios.get(config.apis.areas).then (response => {
                    console.log("allAreas - response", response.data);
                    // setAllAreas(response.data[0]);
                    // console.log(allAreas);
                    const decoded = jwtDecode(token);
                    console.log("TOKEN ",decoded);

                    const interestAreaForMap = [];
                    
                        if(area){
                            console.log("area", area);
                            if(area_2){
                                console.log("area_2", area_2);
                                if(area_3){
                                    console.log("area_3", area_3);
                                    const findArea = ((response.data[0].areas.areasChildren.find((item) => item.areaParent.id === area)).areasChildren.find((item) => item.areaParent.id === area_2)).areasChildren.find((item) => item.areaParent.id === area_3);
                                    visitTreeNodesAreas (findArea, interestAreaForMap);
                                }
                                else{
                                    console.log("area_2", area_2);
                                    const findArea = (response.data[0].areas.areasChildren.find((item) => item.areaParent.id === area)).areasChildren.find((item) => item.areaParent.id === area_2);
                                    visitTreeNodesAreas (findArea, interestAreaForMap);
                                }
                            }
                            else{
                                console.log("area", area);
                                const findArea = response.data[0].areas.areasChildren.find((item) => item.areaParent.id === area);
                                visitTreeNodesAreas (findArea, interestAreaForMap);
                            }
                        }
                        else{
                            console.log("area", area);
                            const findArea = response.data[0].areas;
                            visitTreeNodesAreas (findArea, interestAreaForMap);
                        
                        } 
                        setAreas(interestAreaForMap);
                });
               
            }
        },[props]);

    



    function visitTreeNodesAreas(node, interestAreaForMap) {
        // Verifica se il nodo è una foglia
        if(node.areasChildren.length === 0){
            interestAreaForMap.push(node.areaParent);
           // console.log("Parent", node.areaParent.name);
        }
        // Visita i nodi figli
        for (const child of node.areasChildren) {
            visitTreeNodesAreas(child, interestAreaForMap);
            // console.log("Foglia trovata:", child.name);
        }
      }


    // const configAuthRefresh = {
    //     method: 'post',
    //     url: config.apis.refresh,
    //     headers: { 
    //     'Authorization': `Bearer ${token}`
    //     }
    // };
    
    axios((config.apis.refresh), {headers: { 
        'Authorization': `Bearer ${token}`
        }})
    .then((response) => {
        console.log("refresh_token");
        localStorage.setItem ('token', response.data.token);
        // console.log(localStorage.getItem('token'));
    }).catch((error) => {
        console.log (error);
    });

    
    const center = [37.6214800, 15.0278500];

    
    console.log("Colore Aree", levelsAreas);
    console.log("Nodes", nodes);

    return(
    <>   
    <div className="map-container" style={{ position: 'relative' }}>
        <MapContainer
            center = {center}
            zoom={10}
            style={{ width:'100%', height:'600px'}}
            
            >
                
            <TileLayer
            url="https://api.maptiler.com/maps/topographique/{z}/{x}/{y}.png?key=S3gfxcmlCxV4BLJhSy5P"
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
            />

            
            <Pane name="nodessPane" style={{ zIndex: 1001 }}>
                {nodes.map((node, index) =>

                    //<Marker key={index} position ={[node.lat, node.long]} icon={ markers.get(node.ash_level-1)}> 
                    <Marker key={index} position ={[node.lat, node.long]} style={{ zIndex: 1000, color: "red" }}> 
                        <Popup style={{ zIndex: 10000, fontWeight: 'bold' }}>{node.name} - Level: {node.ash_level}</Popup>
                    </Marker> 
                    
                    )
                }
            </Pane>

            <Pane name="areasPane" style={{ zIndex: 1000 }}>
                {areas && levelsAreas && areas.map((node, index) => {

 
                    return (
                        <Circle center={[node.latitude, node.longitude]} radius={1000} pathOptions= {{ color: levelsAreas[(node.level ? node.level.level +1 : 0)].color }} >
                            <Popup>{node.name}</Popup>    
                        </Circle>
                     )

                })}
            </Pane>
           
                
            
            {/* <Polygon pathOptions={purpleOptions} positions={polygon} /> */}
          

        </MapContainer>

            <Box className="legend-container" style={{ zIndex: 1000, position: 'absolute', top:'20px', right:'10px', backgroundColor:'white', padding:'10px', border: '1px solid gray' }}>
            <h5 style={{textAlign:'center'}}> Ash Level - Area</h5>
            {/* <ul className="legend-list" style={{ listStyleType: 'none' }}> */}
                {areas && levelsAreas && levelsAreas.map((item, index) => (
                    item.code !== -1 && (
                    <li key={item.code} style={{ listStyleType: 'none' }}>
                    
                        <span> {item.code + ' '}</span>
                        <span style={{ backgroundColor: item.color, color:item.color}}> {" _ "} </span>
                        <span> {" - " + item.description}</span>
                    </li>
                    )
                ))}
            {/* </ul> */}
            </Box>
    </div>
    </>
    )

};