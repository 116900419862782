import { useState, useEffect } from 'react';
// import { BrowserRouter as Route, useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import React, { PureComponent } from 'react';
import {Button, Stack, TextField} from '@mui/material';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import { Pagination } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import config from '../config';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
const axios = require('axios').default;

let groupedPerimeters = [];
let arrayDatesLabels = [];
let groupedArea = [];
let groupedAxis1 = [];
let groupedAxis2 = [];
let groupedAxisMajor = [];
const granulometriesClasses = ['[-2,-1]', '[-1,0]', '[0,1]', '[1,2]', '[2,3]'];
const coeff_K_AxisMajor = 0.0331; //axis 1
const coeff_K_AxisMinor = 0.0333; //axis 2
const coeff_K_Perimeter = 0.0333;
const coeff_K_Area = 0.0011;

const coeff_C_AxisMajor = 0.0467; //axis 1
const coeff_C_AxisMinor = 0.0440; //axis 2
const coeff_C_Perimeter = 0.3411;
const coeff_C_Area = 0.1022;



function NodeChart() {
      
  const token = localStorage.getItem('token');    
  const location = useLocation();
  const nameNode = location.state.name;
  const id_node = location.state.id;
  const [infoDates, setInfoDates] = useState([]);
  const [page, setPage] = useState(1);
  const [time, setTime] = useState('');
  const [calendarDate, setCalendarDate] = useState([]);
  const [dateForDownload, setDateForDownload] = useState();
  const [perimeterData, setPerimeterData] = useState([]);
  const [numPagination, setNumPagination] = useState(1);
  const [areaData, setAreaData] = useState([]);
  const [axis1Data, setAxis1Data] = useState([]);
  const [axis2Data, setAxis2Data] = useState([]);
  const [axisMajorData, setAxisMajorData] = useState([]);
  let messageNotice = '';

  
  
   // vengono richiamati le date dove sono presenti misurazioni di granulometrie 
   useEffect(() => {
    const fetchAllDates = async() => {
      axios.get((config.apis.nodes) +"/dates/" + id_node,  {headers: {
        'Authorization': `Bearer ${token}`
        }}).then((response) => {
              console.log ("CHIAMO setInfoDates");
              console.log (response.data);
              if((response.data).length){
                setInfoDates(response.data);
                console.log('ultima data: ', response.data[response.data.length - 1]);
                //setSelectedDate(response.data[response.data.length - 1]);
                infoFilteredDate (response.data[response.data.length - 1]);
                setDateForDownload (response.data[response.data.length - 1]);
              }
              else {
                console.log ("setInfoDates: No data!");
              }
            });
      }
    fetchAllDates();
  },[]);

 
  const infoFilteredDate = (selectedDate) => { //chiamata al cambio data calendario
      console.log("selectedDate", selectedDate);
      axios.get((config.apis.nodes) + "/granulometries/" + id_node +"/"+selectedDate,  {headers: {
        'Authorization': `Bearer ${token}`
        }}).then((response) => {
          console.log("infoFiltered - response", response.data);  
          createChartDataPerimeter(response.data); 
          createChartDataArea(response.data);
          createChartAxis1(response.data);
          createChartAxis2(response.data);
          createChartAxisMajor(response.data);
          initialiseChart(1);
        });
  }

  const getGranulometriesForChart = (axisMajor) =>{
    let granulometriesData = [];
    if(axisMajor){
      const min = -2;
      const max = 3;
      const step = 1;
      const numInteval = 5;
      let associativeArray = {}
      let interval = min;
      for (let i = 0; i < numInteval; i++) {
        interval =  interval + step;
        let countInterval = 0;
        if (i === numInteval-1){ //per l'ultimo intevallo confronto con max
        for (let j = 0; j < axisMajor.length; j++) {
            console.log(axisMajor[j]);
            if (axisMajor[j] <= max && axisMajor[j] >= interval-step) {
              countInterval++;
            }
          }
        }
        else{
          for (let j = 0; j < axisMajor.length; j++) {
            console.log("axisMajor", axisMajor[j]);
            if (axisMajor[j] <= interval && axisMajor[j] >= interval-step) {
                countInterval++;
            }
          }
        }
        let labelInteval = granulometriesClasses[i];
        associativeArray[labelInteval] = countInterval++;
        //console.log( associativeArray[labelInteval]);
      }
     
      for (var key in associativeArray) {
        granulometriesData.push({"mm":key, "sample":associativeArray[key] });
      }
    }
    //console.log (granulometriesData);
    return granulometriesData;
 
  };
  
  const getParametersForChart = (parametersArray) => {
    let paramaterData = [];
    if (parametersArray){
      const min = Math.min.apply(Math, parametersArray);
      //console.log(min);
      const max = Math.max.apply(Math, parametersArray);
      //console.log(max);
      const numInteval = 10;
      const step = (max-min)/numInteval;
      //console.log (step);
      let associativeArray = {}
      let interval = min;
      for (let i = 0; i < numInteval; i++) {
        interval =  interval + step;
        let countInterval = 0;
        if (i === numInteval-1){ //per l'ultimo intevallo confronto con max
        for (let j = 0; j < parametersArray.length; j++) {
            //console.log(parametersArray[j]);
            if (parametersArray[j] <= max && parametersArray[j] >= interval-step) {
              countInterval++;
            }
          }
        }
        else{
          for (let j = 0; j < parametersArray.length; j++) {
            //console.log(parametersArray[j]);
            if (parametersArray[j] <= interval && parametersArray[j] >= interval-step) {
                countInterval++;
            }
          }
        }
        let averageInteval = interval - (step/2);
        associativeArray[averageInteval] = countInterval++;
      }
      //console.log(associativeArray);

     
      for (var key in associativeArray) {
        paramaterData.push({"mm":Number(key).toFixed(2), "sample":associativeArray[key] });
      }
    }
    return paramaterData;
    
  };

  const handleChange = (e, p) => {
    setPage(p);
    initialiseChart (p);
  };

  const initialiseChart = (p) => {
    console.log("array perimetro", groupedPerimeters[p]);
    //infoFilteredDate(infoDates[infoDates.length - 1]);
    let dateTmp = new Date(arrayDatesLabels[p]);
    // setDate(dateTmp.toISOString().split("T")[0]+ " " + dateTmp.toTimeString().split(" ")[0]);
    setTime(dateTmp.toTimeString().split(" ")[0]);
    setCalendarDate (dateTmp.toISOString().split("T")[0]);
    setPerimeterData(getParametersForChart(groupedPerimeters[p]));
    setAreaData(getParametersForChart(groupedArea[p]));
    setAxis1Data(getParametersForChart (groupedAxis1[p]));
    setAxis2Data(getParametersForChart(groupedAxis2[p]));
    setAxisMajorData(getGranulometriesForChart(groupedAxisMajor[p]));
    setNumPagination(arrayDatesLabels.length - 1);
  }
    
  
  
  const createChartDataPerimeter = (infoFiltered) =>{
    console.log("CHIAMO createChartDataPerimeter");
    let counter  = 0; 
    let tempGroupedPerimeters = [];
    let tempArrayDatesLabels = [];
    infoFiltered.forEach(item => {
      counter++;
      tempGroupedPerimeters[counter] = [];
      item.read_json_data.forEach(read_json_item => {
        //si deve creare l'array dei perimetri e fare il set
        tempGroupedPerimeters[counter].push((read_json_item.perimeter*coeff_K_Perimeter)+coeff_C_Perimeter);
      });
      tempArrayDatesLabels[counter] = item.read_datetime;
    
    })
    groupedPerimeters = tempGroupedPerimeters;
    arrayDatesLabels = tempArrayDatesLabels;
   
  }

  const createChartDataArea = (infoFiltered) =>{
    console.log("CHIAMO createChartDataArea");
    let counter  = 0; 
    let tempGroupedArea = [];
    infoFiltered.forEach(item => {
      counter++;
      tempGroupedArea[counter] = [];
      item.read_json_data.forEach(read_json_item => {
        tempGroupedArea[counter].push((read_json_item.area*coeff_K_Area)+coeff_C_Area);
      });
    })
    console.log('tempGroupedArea',tempGroupedArea);
    groupedArea = tempGroupedArea;
    
  }

  const createChartAxis1 = (infoFiltered) =>{
    console.log("CHIAMO createChartAxis1");
    let counter  = 0; 
    let tempGroupedAxis1 = [];
    infoFiltered.forEach(item => {
      counter++;
      tempGroupedAxis1[counter] = [];
      item.read_json_data.forEach(read_json_item => {
        tempGroupedAxis1[counter].push((read_json_item.boundAxis1*coeff_K_AxisMajor)+coeff_C_AxisMajor);
      });
    })
    console.log('tempGroupedAxis1',tempGroupedAxis1);
    groupedAxis1 = tempGroupedAxis1;
    
  }

  const createChartAxis2 = (infoFiltered) =>{
    console.log("CHIAMO createChartAxis2");
    let counter  = 0; 
    let tempGroupedAxis2 = [];
    infoFiltered.forEach(item => {
      counter++;
      tempGroupedAxis2[counter] = [];
      item.read_json_data.forEach(read_json_item => {
        tempGroupedAxis2[counter].push((read_json_item.boundAxis2*coeff_K_AxisMinor)+coeff_C_AxisMinor);
      });
    })
    console.log('tempGroupedAxis2',tempGroupedAxis2);
    groupedAxis2 = tempGroupedAxis2;
    
  }

  const createChartAxisMajor = (infoFiltered) =>{
    console.log("CHIAMO createChartAxisMajor");
    let counter  = 0; 
    let tempGroupedAxisMajor = [];
    infoFiltered.forEach(item => {
      counter++;
      tempGroupedAxisMajor[counter] = [];
      item.read_json_data.forEach(read_json_item => {
        console.log(read_json_item.boundAxis2);
        let ma
        tempGroupedAxisMajor[counter].push((Math.log2((Math.max((read_json_item.boundAxis2,read_json_item.boundAxis1))*coeff_K_AxisMajor)+coeff_C_AxisMajor))*(-1));
      });
    })
    console.log('tempGroupedAxisMajor',tempGroupedAxisMajor);
    groupedAxisMajor = tempGroupedAxisMajor;
    
  }

 
  

  // let infoNodes = Object.entries(info);

  // console.log('infoNodes',infoNodes);
  // const map1 = infoNodes.map(x => x = x.read_uid_operation);
  // console.log(map1);

 //raggruppamento dei dati della granulometria in base alla read_uid_operation

  // const groupData = infoNodes.reduce((acc, value) => {
  //   if(!acc[value.read_uid_operation]) {
  //     acc[value.read_uid_operation] = [];
  //   }
  //   acc[value.read_uid_operation].push(value);
  //   return acc;
  // }, {});

  // const groups = info.reduce((groups, item) => {

  //   const group = (groups[item.read_uid_operation] || []);
  //   group.push(item);
  //   groups[item.read_uid_operation] = group;
  //   return groups;
  // }, {});
  
  // console.log(groups);

  // groups.map((k,v) => {
  //   setPerimeters(v.perimeter);
  // })


 

  const isDesktop = useMediaQuery('(min-width: 660px)');



  //const parametersArray = [371.012, 375.113, 371.698, 367.941, 363.941, 389.255, 380.770, 369.698, 377.598, 375.698, 397.598, 385.698, 386.669, 377.698, 384.284, 385.113, 393.598, 397.841, 390.426, 388.426, 380.184, 394.184, 391.941, 388.184, 397.941];
  //let perimeterData = getParametersForChart(parametersArray);
  //setPerimeterData(getParametersForChart(groupedPerimeters.length));


    const params = useLocation();
    // const data = [
    //     {
    //       mm: 2,
    //       uv: 4000,
    //       amt: 2400,
    //     },
    //     {
    //       mm: 4,
    //       uv: 3000,
    //       amt: 2210,
    //     },
    //     {
    //       mm: 6,
    //       uv: 2000,
    //       amt: 2290,
    //     },
    //     {
    //       mm: 10,
    //       uv: 2780,
    //       amt: 2000,
    //     },
    //     {
    //       mm: 8,
    //       uv: 1890,
    //       amt: 2181,
    //     },
    //     {
    //       mm: 15,
    //       uv: 2390,
    //       amt: 2500,
    //     },
    //     {
    //       mm: 12,
    //       uv: 3490,
    //       amt: 2100,
    //     },
    //     {
    //       mm: 18,
    //       uv: 2390,
    //       amt: 2500,
    //     },
    //     {
    //       mm: 16,
    //       uv: 3490,
    //       amt: 2100,
    //     },
    //     {
    //       m: 13,
    //       uv: 2390,
    //       amt: 2500,
    //     },
    //     {
    //       m: 17,
    //       uv: 3490,
    //       amt: 2100,
    //     },
    //   ];


        
   //console.log("****axisMajorData",axisMajorData);
   const allElementsAreZero = axisMajorData.every(item => item.sample === 0);
   console.log("axisMajorData", axisMajorData);

    if (allElementsAreZero) {
      messageNotice = "Nessun campione di cenere risulta valido per la classificazione granulometrica";
    }

    return (

        <>
        
        
        <Stack alignItems='center' spacing={4} position="relative">
              <div style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: '#F7F8F8', paddingTop: '2%', paddingBottom: '1%'}}>
               <Box style={{ textAlign:'center', marginBottom: '2%', color:'#cc3333', fontWeight: 'bold'}}> {nameNode} </Box>    
               <Box display="flex" justifyContent="center" alignItems="center">
               
                <Button variant="contained"  style={{backgroundColor:"#cc3333", marginRight: '2%'}} onClick={ async() => {

               const response = await axios.get((config.apis.nodes) + "/xml/" + id_node +"/" + dateForDownload, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                 
                    
                    const blob = new Blob([response.data], { type: 'text/xml' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Node_'+ id_node +'_'+ dateForDownload;
                    link.click();
            
               
                }
                }> Download 
                </Button>
                  

               <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    label="Seleziona la data"
                    disableFuture
                    views={['year', 'month', 'day']}
                    value={calendarDate}
                    minDate={dayjs('2022-01-01')}
                    onChange={(newDate) => {
                      if(newDate){
                        setCalendarDate(dayjs(newDate.format('YYYY-MM-DD')));
                        console.log('call infoFilteredDate', newDate);
                        infoFilteredDate(newDate.format('YYYY-MM-DD'));
                        setDateForDownload (newDate.format('YYYY-MM-DD'));
                        initialiseChart(1);
                      }
                    }}

                    shouldDisableDate={(dayjsDate) => {
                      for (let i = 0; i < infoDates.length; i++) {
                          let date = dayjs(infoDates[i]);
                          if (date.isSame(dayjsDate)) {
                              return false;
                          }
                      }
                      return true;
                    }}
                    inputFormat='YYYY-MM-DD'
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <label style = {{ border: '1px solid #ccc',
                                  borderRadius: '4px',
                                  padding: '16.5px',
                                  marginLeft: '10px',
                                  position: 'relative', // rende la posizione relativa all'elemento genitore
                                }}> 
                                  {time}  
                                  <span
                                    style={{
                                      position: 'absolute', // rende la posizione assoluta all'interno della label
                                      top: '-10px', // posiziona il testo sopra il bordo
                                      left: '25%', // posiziona il testo a metà della larghezza della label
                                      transform: 'translateX(-50%)', // centra il testo orizzontalmente
                                      background: '#F7F8F8', // rende lo sfondo trasparente
                                      padding: '0 4px', // aggiunge un po' di spazio interno al testo
                                      fontSize: '12px', // imposta la dimensione del font
                                      color: 'rgba(0,0,0,0.6)'
                                    }}
                                  >
                                  Ora
                                </span>  
                </label>
                
                </Box>
                    {/* <Box textAlign='center' >
                      <label> {date}  </label>
                    </Box> */}
                    <Stack alignItems='center' marginTop="15px">
                      <Pagination
                        count={numPagination}
                        size="large"
                        page={page}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Stack>
                    <Box style={{ textAlign:'center', marginBottom: '2%', color:'#cc3333'}}> {messageNotice} </Box>   
                </div>
               
                {!messageNotice && (
                <Box>
                  <h4>Granulometries</h4>
                  <BarChart
                    width={isDesktop ? 600: 300}
                    height={isDesktop ? 400: 200}
                    data={axisMajorData}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="33" />
                    <XAxis dataKey="mm" />
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                      <Bar dataKey="sample" fill="#ce3b33" />
                  </BarChart>
                </Box>
                )} 

                
                <Box>
                  <h4>Perimeter</h4>
                  <BarChart
                    width={isDesktop ? 600: 300}
                    height={isDesktop ? 400: 200}
                    data={perimeterData}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="33" />
                    <XAxis dataKey="mm" />
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                      <Bar dataKey="sample" fill="#ce3b33" />
                  </BarChart>
                </Box> 
               
                
                <Box>
                  <h4>Area</h4>
                  <BarChart
                      width={isDesktop ? 600: 300}
                      height={isDesktop ? 400: 200}
                      data={areaData}
                      margin={{
                        top: 10,
                        right: 0,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="33" />
                      <XAxis dataKey="mm" />
                      <YAxis/>
                      <Tooltip />
                      <Legend />
                        <Bar dataKey="sample" fill="#ce3b33" />
                  </BarChart>
                </Box>
                
               
                <Box>
                  <h4>BoundAxis1</h4>
                  <BarChart
                    width={isDesktop ? 600: 300}
                    height={isDesktop ? 400: 200}
                    data={axis1Data}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="33" />
                    <XAxis dataKey="mm" />
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                      <Bar dataKey="sample" fill="#ce3b33" />
                  </BarChart>
                </Box> 
                
                <Box>
                  <h4>BoundAxis2</h4>
                  <BarChart
                    width={isDesktop ? 600: 300}
                    height={isDesktop ? 400: 200}
                    data={axis2Data}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="33" />
                    <XAxis dataKey="mm" />
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                      <Bar dataKey="sample" fill="#ce3b33" />
                  </BarChart>
                </Box> 
              </Stack>
               
            {/* </Box> */}
 

      </>
    )
}


export default NodeChart;