import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";


import Auth from './core/viewmodels/Auth';
import SiteContent from './core/viewmodels/SiteContent';
// import Site from './core/viewmodels/Site';

import './App.css';
import { SettingsSystemDaydreamSharp } from '@mui/icons-material';



function App() { 

  let main = <div>No Website.</div>;



  const [token, setToken] = useState('');


  useEffect(() => {
    console.log('app')
    const lsToken = localStorage.getItem('token') ?? undefined
    
    if(lsToken){
      setToken(lsToken);
    }
  }, [])

  const Login = (response) => {

    if(response.data.token){

      console.log('login')
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('exp', response.data.exp);

      setToken(response.data.token);
    }
  }

  
  const Logout = () => {
    localStorage.clear();
    setToken(undefined);
 
  }
 

  if(token) {
    main = <SiteContent logout={Logout} />; 
  }else{

    main = <Auth login={Login}/> //UserLogin e MarketLogin sono Props
  }

  return (
    main
  );


}

export default App;


